<template>
  <span>
    <v-row dense justify="center" class="mt-5">
      <v-col
        v-for="indicador in Object.keys(dadosTotais)" 
        :key="indicador"
        cols="5" sm="3" md="auto"
        class="flex-md-grow-1"
      >
        <v-card class="my-1">
          <v-card-title>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <h5 v-on="on">{{ indicador | toUpperCase }}</h5>
              </template>
              <span>{{ traducaoIndicadores[indicador] }}</span>
            </v-tooltip>
            <status-evolucao-kpis
              class="ml-1"
              :valorAtual="dadosTotais[indicador] || 0"
              :valorAnterior="dadosTotaisCompetenciaAnterior[indicador] || 0"
            />
          </v-card-title>
          <v-card-text>
            <div class="display-3">
              {{
                dadosTotais[indicador] === null
                  ? "-"
                  : dadosTotais[indicador] | parseNumberToFloatBrIgnoringNaN
              }}
              <span v-if="['pla', 'pla+e'].includes(indicador)">%</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense justify="center">
      <v-col cols="12" md="8" xl="8">
        <v-card class="my-1">
          <v-card-title>
            <h5>Situação das Solicitações</h5>
          </v-card-title>
          <v-card-text>
            <highcharts 
              :options=chartOptionsSituacaoSolicitacoes
              :deepCopyOnUpdate="false" 
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card class="my-1">
          <v-card-title>
            <h5>Tipologias das Solicitações</h5>
          </v-card-title>
          <v-card-text>
            <highcharts 
              :options=chartOptionsTipologiasSolicitacoes 
              :deepCopyOnUpdate="false" 
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card class="my-1">
          <v-card-title>
            <h5>Localização das Obras</h5>
          </v-card-title>
          <v-card-text>
            <highcharts 
              :options=chartOptionsLocSolicitacoes 
              :deepCopyOnUpdate="false" 
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card class="my-1">
          <v-card-title>
            <h5>Localização das Obras em Atraso</h5>
          </v-card-title>
          <v-card-text>
            <highcharts 
              :options=chartOptionsLocSolicitacoesEmAtraso
              :deepCopyOnUpdate="false" 
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card class="my-1">
          <v-card-title>
            <h5>Tipologias das Solicitações em Atraso</h5>
          </v-card-title>
          <v-card-text>
            <highcharts 
              :options=chartOptionsTipologiasSolicitacoesEmAtraso
              :deepCopyOnUpdate="false" 
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense justify="center">
      <v-col cols="12" sm="6">
        <v-card class="my-1">
          <v-card-title>
            <h5>QLP - Qtde. de Ligações com obra pendentes não suspensas</h5>
          </v-card-title>
          <v-card-text>
            <highcharts 
              :options=chartOptionsQlpLno 
              :deepCopyOnUpdate="false" 
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card class="my-1">
          <v-card-title>
            <h5>QLP - Qtde. de Ligações com obra pendentes não suspensas</h5>
          </v-card-title>
          <v-card-text>
            <highcharts 
              :options=chartOptionsQlpMultiSeries
              :deepCopyOnUpdate="false" 
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card class="my-1">
          <v-card-title>
            <h5>PLA - Percentual de ligações com obra atrasadas</h5>
          </v-card-title>
          <v-card-text>
            <highcharts 
              :options=chartOptionsPlaLno 
              :deepCopyOnUpdate="false" 
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card class="my-1">
          <v-card-title>
            <h5>PLA+E - Percentual de ligações com obra atrasadas (com Estudo)</h5>
          </v-card-title>
          <v-card-text>
            <highcharts 
              :options=chartOptionsPlaeLno 
              :deepCopyOnUpdate="false" 
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card class="my-1">
          <v-card-title>
            <h5>QLT - Qtde. de Ligações Totais (pendentes + suspensas)</h5>
          </v-card-title>
          <v-card-text>
            <highcharts 
              :options=chartOptionsQlt
              :deepCopyOnUpdate="false" 
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card class="my-1"> 
          <v-card-title>
            <h5>PAT1 - Prazo Médio de Lig. Atrasada do Tipo 1</h5>
          </v-card-title>
          <v-card-text>
            <highcharts 
              :options=chartOptionsPat1Lno 
              :deepCopyOnUpdate="false" 
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card class="my-1">
          <v-card-title>
            <h5>PAT2 - Prazo Médio de Lig. Atrasada do Tipo 2</h5>
          </v-card-title>
          <v-card-text>
            <highcharts 
              :options=chartOptionsPat2Lno 
              :deepCopyOnUpdate="false" 
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card class="my-1">
          <v-card-title>
            <h5>PAT3 - Prazo Médio de Lig. Atrasada do Tipo 3</h5>
          </v-card-title>
          <v-card-text>
            <highcharts 
              :options=chartOptionsPat3Lno 
              :deepCopyOnUpdate="false" 
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card class="my-1">
          <v-card-title>
            <h5>PAT4 - Prazo Médio de Lig. Atrasada do Tipo 4</h5>
          </v-card-title>
          <v-card-text>
            <highcharts 
              :options=chartOptionsPat4Lno 
              :deepCopyOnUpdate="false" 
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card class="my-1">
          <v-card-title>
            <h5>PMEA - Prazo Médio Lig. Pend. P/ Elab. de Est., Orç. e Projetos Atrasado</h5>
          </v-card-title>
          <v-card-text>
            <highcharts 
              :options=chartOptionsPmeaLno 
              :deepCopyOnUpdate="false" 
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card class="my-1">
          <v-card-title>
            <h5>PMEA2 - Prazo Médio Lig. Pend. P/ Elab. de Est., Orç. e Projetos Atrasado (2)</h5>
          </v-card-title>
          <v-card-text>
            <highcharts 
              :options=chartOptionsPmea2Lno 
              :deepCopyOnUpdate="false" 
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card class="my-1">
          <v-card-title>
            <h5>QLA - Qtde. de Ligações Atrasadas</h5>
          </v-card-title>
          <v-card-text>
            <highcharts 
              :options=chartOptionsQla 
              :deepCopyOnUpdate="false" 
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </span>
</template>

<script>

import DashboardLnoService from "@/services/DashboardLnoService";

import Highcharts from 'highcharts';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';
HighchartsNoData(Highcharts);

import exporting from 'highcharts/modules/exporting';
import exportData from 'highcharts/modules/export-data';
exporting(Highcharts);
exportData(Highcharts);

import dayjs from 'dayjs';

export default {
  name: 'DashboardLnoVisaoAneel',
  components: {
    StatusEvolucaoKpis: () => import("@/components/dashboardLno/StatusEvolucaoKpis.vue")
  },
  props: {
    competencia: {
      type: String,
      required: true,
    },
    tipoCalculo: {
      type: String,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    activatedTab: {
      type: Number,
      required: true,
    },
    traducaoIndicadores: {
      type: Object,
      default: {}
    },
    regionalSelecionadaId: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    dadosPorCompetencia: {},
    metasKpis: {},
    dadosTotais: {},
    dadosTotaisCompetenciaAnterior: {},
    dadosLocSolicitacoes: [],
    dadosTipologiasSolicitacoes: [],
    dadosSituacaoSolicitacoes: [],
    dadosQlpMultiSeries: [],
    dadosQlt: [],
    chartOptionsLocSolicitacoes: {},
    chartOptionsLocSolicitacoesEmAtraso: {},
    chartOptionsTipologiasSolicitacoes: {},
    chartOptionsTipologiasSolicitacoesEmAtraso: {},
    chartOptionsSituacaoSolicitacoes: {},
    chartOptionsQlpMultiSeries: {},
    chartOptionsQlt: {},
    chartOptionsQlpLno: {},
    chartOptionsPlaLno: {},
    chartOptionsPlaeLno: {},
    chartOptionsPmeaLno: {},
    chartOptionsPmea2Lno: {},
    chartOptionsPat1Lno: {},
    chartOptionsPat2Lno: {},
    chartOptionsPat3Lno: {},
    chartOptionsPat4Lno: {},
    chartOptionsQla: {},
    exportingOptions:{
      enabled: true,

        buttons: {
            contextButton: {
              align: 'left',
              verticalAlign: 'bottom',
              menuItems: [
              {
                        textKey: 'downloadPNG',
                        text: 'Baixar como PNG',
                        onclick: function () {
                            this.exportChart();
                        }
                    },
                    {
                        separator: true
                    },
                    {
                        textKey: 'downloadCSV',
                        text: 'Baixar como CSV',
                        onclick: function () {
                            this.downloadCSV();
                        }
                    },
                    {
                        textKey: 'downloadXLS',
                        text: 'Baixar como XLS',
                        onclick: function () {
                            this.downloadXLS();
                        }
                    }
              ]
            }
        }
    }
  }),
  mounted() {
    this.getDados();
  },
  methods: {
    getDados() {
      // Apenas atualizado os dados se for a visao selecionada
      if (this.activatedTab !== 1) return; 

      this.$emit("updateLoadingDados", true);

      Promise.all([
        this.getDadosTotais(),
        this.getDadosTotaisCompetenciaAnterior(),
        this.getDadosPorCompetencia(),
        this.getMetasKpisGeral(),
        this.getDadosLocalizacaoSolicitacoes(),
        this.getDadosTipologiasSolicitacoes(),
        this.getDadosSituacaoSolicitacoes(),
        this.getDadosQlpMultiSeries(),
        this.getDadosQlt(),
      ])
        .catch(err => {
          console.error(err);
          this.$toast.error('Erro recuperar dados.', '', { position: 'topRight' });
        })
        .finally(() => this.$emit("updateLoadingDados", false));
    },
    getDadosTotais() {
      return DashboardLnoService.getDadosTotaisIndicadoresLno(
        this.competencia, 
        this.competencia,
        this.tipoCalculo,
        this.regionalSelecionadaId
      )
        .then(res => {
          this.dadosTotais = res;
        })
        .catch(err => {
          throw err;
        });
    },
    getDadosTotaisCompetenciaAnterior() {
      return DashboardLnoService.getDadosTotaisIndicadoresLno(
        dayjs(this.competencia).subtract(1, 'month').format('YYYY-MM'), 
        dayjs(this.competencia).subtract(1, 'month').format('YYYY-MM'),
        this.tipoCalculo,
        this.regionalSelecionadaId
      )
        .then(res => {
          this.dadosTotaisCompetenciaAnterior = res;
        })
        .catch(err => {
          throw err;
        });
    },
    getDadosPorCompetencia() {
      return DashboardLnoService.getDadosIndicadoresLno(
        "2023-01",
        dayjs().format("YYYY-MM"),
        this.tipoCalculo,
        this.regionalSelecionadaId
      )
        .then(res => {
          this.dadosPorCompetencia = res;

          this.chartOptionsQlpLno = this.getChartOptionsIndicadores('qlp');
          this.chartOptionsPlaLno = this.getChartOptionsIndicadores('pla');
          this.chartOptionsPlaeLno = this.getChartOptionsIndicadores('pla+e');
          this.chartOptionsPmeaLno = this.getChartOptionsIndicadores('pmea');
          this.chartOptionsPmea2Lno = this.getChartOptionsIndicadores('pmea2');
          this.chartOptionsPat1Lno = this.getChartOptionsIndicadores('pat1');
          this.chartOptionsPat2Lno = this.getChartOptionsIndicadores('pat2');
          this.chartOptionsPat3Lno = this.getChartOptionsIndicadores('pat3');
          this.chartOptionsPat4Lno = this.getChartOptionsIndicadores('pat4');
        })
        .catch(err => {
          throw err;
        });
    },
    getMetasKpisGeral() {
      return DashboardLnoService.getMetasKpisGeral(
        "2023-01",
        dayjs().format("YYYY-MM")
      )
        .then(res => {
          this.metasKpis = res;
        })
        .catch(err => {
          throw err;
        });
    },
    getDadosLocalizacaoSolicitacoes() {
      return DashboardLnoService.getDadosLocalizacoesSolicitacoes(
        this.competencia, 
        this.competencia,
        this.tipoCalculo,
        this.regionalSelecionadaId
      )
        .then(res => {
          this.dadosLocSolicitacoes = res;
          
          this.chartOptionsLocSolicitacoes = this.getPieChartOptions(
            this.getLocSolicitacoesChartSeriesData({
              urbano: this.dadosLocSolicitacoes.urbano.solicitacoes,
              rural: this.dadosLocSolicitacoes.rural.solicitacoes
            })
          );
          this.chartOptionsLocSolicitacoesEmAtraso = this.getPieChartOptions(
            this.getLocSolicitacoesChartSeriesData({
              urbano: this.dadosLocSolicitacoes.urbano.solicitacoes_em_atraso,
              rural: this.dadosLocSolicitacoes.rural.solicitacoes_em_atraso
            })
          );
        })
        .catch(err => {
          throw err;
        });
    },
    getDadosTipologiasSolicitacoes() {
      return DashboardLnoService.getDadosTipologiaSolicitacoes(
        this.competencia, 
        this.competencia,
        this.tipoCalculo,
        this.regionalSelecionadaId
      )
        .then(res => {
          this.dadosTipologiasSolicitacoes = res;

          this.chartOptionsTipologiasSolicitacoes = this.getPieChartOptions(
            this.getTipologiasSolicitacoesChartSeriesData({
              tipo_1: this.dadosTipologiasSolicitacoes.tipo_1.solicitacoes,
              tipo_2: this.dadosTipologiasSolicitacoes.tipo_2.solicitacoes,
              tipo_3: this.dadosTipologiasSolicitacoes.tipo_3.solicitacoes,
              tipo_4: this.dadosTipologiasSolicitacoes.tipo_4.solicitacoes,
              orcamento: this.dadosTipologiasSolicitacoes.orcamento.solicitacoes,
            })
          );
          this.chartOptionsTipologiasSolicitacoesEmAtraso = this.getPieChartOptions(
            this.getTipologiasSolicitacoesChartSeriesData({
              tipo_1: this.dadosTipologiasSolicitacoes.tipo_1.solicitacoes_em_atraso,
              tipo_2: this.dadosTipologiasSolicitacoes.tipo_2.solicitacoes_em_atraso,
              tipo_3: this.dadosTipologiasSolicitacoes.tipo_3.solicitacoes_em_atraso,
              tipo_4: this.dadosTipologiasSolicitacoes.tipo_4.solicitacoes_em_atraso,
              orcamento: this.dadosTipologiasSolicitacoes.orcamento.solicitacoes_em_atraso,
          })
          );
        })
        .catch(err => {
          throw err;
        });
    },
    getDadosSituacaoSolicitacoes() {
      return DashboardLnoService.getDadosSituacaoSolicitacoes(
        this.competencia, 
        this.competencia,
        this.tipoCalculo,
        this.regionalSelecionadaId
      )
        .then(res => {
          this.dadosSituacaoSolicitacoes = res;
          
          this.chartOptionsSituacaoSolicitacoes = this.getPieChartOptions(
            this.getSituacaoSolicitacoesChartSeriesData(this.dadosSituacaoSolicitacoes)
          );
        })
        .catch(err => {
          throw err;
        });
    },
    getDadosQlpMultiSeries() {
      return DashboardLnoService.getDadosQlpMultiSeries(
        "2023-01",
        dayjs().format("YYYY-MM"),
        this.tipoCalculo,
        this.regionalSelecionadaId
      )
        .then(res => {
          this.dadosQlpMultiSeries = res;
          
          this.chartOptionsQlpMultiSeries = this.getAreaSplineChartOptions(
            this.getQlpMultiSeriesChartSeries(this.dadosQlpMultiSeries)
          );

          this.setDadosQla();
        })
        .catch(err => {
          throw err;
        });
    },
    setDadosQla() {
      this.dadosPorCompetencia['qla'] = this.dadosQlpMultiSeries.obra_atrasada;

      this.chartOptionsQla = this.getChartOptionsIndicadores('qla', false);
    },
    getDadosQlt() {
      return DashboardLnoService.getDadosQlt(
        "2023-01",
        dayjs().format("YYYY-MM"),
        this.tipoCalculo,
        this.regionalSelecionadaId
      )
        .then(res => {
          this.dadosQlt = res;
          
          this.chartOptionsQlt = this.getAreaSplineChartOptions(
            this.getQltChartSeries(this.dadosQlt)
          );
        })
        .catch(err => {
          throw err;
        });
    },
    getChartOptionsIndicadores(indicador, showLegend = true) {
      const chartSeries = [
          {
            name: "Valor",
            animation: false,
            data: this.categories
              .map(category => {
                return category in this.dadosPorCompetencia[indicador] 
                  ? { x: this.categories.indexOf(category), y: Number(this.dadosPorCompetencia[indicador][category]) } 
                  : { x: this.categories.indexOf(category), y: 0 }
              })
              .filter(val => val !== null && val.y !== null && val.y > 0)
          },
          {
            name: "Meta",
            animation: false,
            stickyTracking: false,
            type: "spline",
            data: this.categories
              .map(category => {
                return category in this.metasKpis
                  ? { x: this.categories.indexOf(category), y: Number(this.metasKpis?.[category]?.[indicador]) } 
                  : { x: this.categories.indexOf(category), y: null }
              })
              .filter(val => val !== null && val.y !== null)
          }
        ];

        if (indicador.startsWith('pat')) {
          chartSeries.push(
            {
              name: "Qtde. de ligações atrasadas",
              type: "spline",
              animation: false,
              yAxis: 1,
              stickyTracking: false,
              color: "#D3D3D3",
              plotOptions: {
                series: {
                  borderWidth: 0,
                  dataLabels: {
                    enabled: true,
                  },
                },
              },
              data: this.categories
                .map(category => {
                  return category in this.dadosPorCompetencia[`qtd_ligacoes_atrasadas_${indicador}`] 
                    ? { x: this.categories.indexOf(category), y: Number(this.dadosPorCompetencia[`qtd_ligacoes_atrasadas_${indicador}`][category])  } 
                    : { x: this.categories.indexOf(category), y: null }
                })
                .filter(val => val !== null && val.y !== null && val.y > 0)
            }
          );
        }

      return {
        chart: {
          type: 'column',
          zooming: {
            type: "x",
          },
          height: 400,
        },
        credits: { enabled: false },
        title: { text: null },
        subtitle: { text: null },
        legend: { enabled: showLegend },
        exporting: this.exportingOptions,
        tooltip: { 
          shared: true,
          followPointer: true,
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
            },
          },
        },
        xAxis: {
          categories: this.categories,
          crosshair: true,
          title: { 
            text: 'Competência'
          }
        },
        yAxis: [
          {
            title: { text: null },
            max: ['pla', 'pla+e'].includes(indicador) ? 100 : null,
          },
          {
            title: { text: null },
            opposite: true,
          },
        ],
        lang: {
          noData: "Sem dados para exibição"
        },
        series: chartSeries,
      }; 
    },
    getPieChartOptions(seriesData) {
      return {
        chart: {
          type: 'pie',
          height: 250,
        },
        exporting: this.exportingOptions,
        // colors: [],
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              distance: 30,
              formatter: function() { 
                return `${this.point.name}: ${parseFloat(this.point.y).toLocaleString('pt-BR')}` 
                // return parseFloat(this.point.y).toLocaleString('pt-BR')
              }
            },
            showInLegend: true
          }
        },
        credits: { enabled: false },
        title: { text: null },
        subtitle: { text: null },
        legend: { enabled: false },
        lang: {
          noData: "Sem dados para exibição"
        },
        series: {
          name: "Valor",
          colorByPoint: true,
          innerSize: '75%',
          animation: false,
          data: seriesData
        },
      }; 
    },
    getLocSolicitacoesChartSeriesData(dados) {
      return [
        { name: "Urbano", y: dados.urbano },
        { name: "Rural", y: dados.rural }
      ];
    },
    getTipologiasSolicitacoesChartSeriesData(dados) {
      return [
        { name: "Tipo 1", y: dados.tipo_1 },
        { name: "Tipo 2", y: dados.tipo_2 },
        { name: "Tipo 3", y: dados.tipo_3 },
        { name: "Tipo 4", y: dados.tipo_4 },
        { name: "Orçamento", y: dados.orcamento }
      ];
    },
    getSituacaoSolicitacoesChartSeriesData(dados) {
      return [
        { name: "Obra no Prazo", y: dados.obra_no_prazo },
        { name: "Obra Atrasada", y: dados.obra_atrasada },
        { name: "Obra Suspensa", y: dados.obra_suspensa },
        { name: "Orçamento no Prazo", y: dados.orcamento_no_prazo },
        { name: "Orçamentos Atrasados", y: dados.orcamentos_atrasados },
        { name: "Orçamento Suspensos", y: dados.orcamentos_suspensos }
      ];
    },
    getAreaSplineChartOptions(series) {
      return {
        chart: {
          type: 'area',
          zooming: {
            type: "x",
          },
          height: 400,
        },
        // colors: [],
        credits: { enabled: false },
        title: { text: null },
        subtitle: { text: null },
        legend: { enabled: true },
        tooltip: { 
          shared: true,
        },
        plotOptions: {
          series: {
            marker: { enabled: false },
          },
          area: {
            stacking: 'normal',
          }
        },
        xAxis: {
          categories: this.categories,
          crosshair: true,
          title: { 
            text: 'Competência'
          }
        },
        yAxis: {
          title: { text: null },
        },
        lang: {
          noData: "Sem dados para exibição"
        },
        series: series,
        exporting: this.exportingOptions,

      }; 
    },
    getQlpMultiSeriesChartSeries(dados) {
      const seriesInfo = [
        { title: "Orçamentos Atrasados", varName: "orcamentos_atrasados" },
        { title: "Orçamento no Prazo", varName: "orcamento_no_prazo" },
        { title: "Obra Atrasada", varName: "obra_atrasada" },
        { title: "Obra no Prazo", varName: "obra_no_prazo" },
      ]
      
      return seriesInfo
        .map(serie => {
          return {
            name: serie.title,
            animation: false,
            data: this.categories
              .map(category => {
                return category in dados[serie.varName] 
                  ? { x: this.categories.indexOf(category), y: Number(dados[serie.varName][category]) } 
                  : { x: this.categories.indexOf(category), y: null }
              })
              .filter(val => val !== null && val.y !== null && val.y > 0)
          }
        });
    },
    getQltChartSeries(dados) {
      const seriesInfo = [
        { title: "Ligações com obra suspensas", varName: "lig_obra_suspensas" },
        { title: "Ligações com obra pendentes", varName: "lig_obra_pendentes" },
      ];

      return seriesInfo
        .map(serie => {
          return {
            name: serie.title,
            animation: false,
            data: this.categories
              .map(category => {
                return category in dados 
                  ? { x: this.categories.indexOf(category), y: Number(dados[category][serie.varName]) } 
                  : { x: this.categories.indexOf(category), y: null }
              })
              .filter(val => val !== null && val.y !== null && val.y > 0)
          }
        });
    },
  },
    watch: {
    activatedTab() {
      this.getDados();
    },
    competencia() {
      this.getDados();
    },
    tipoCalculo() {
      this.getDados();
    },
    regionalSelecionadaId() {
      this.getDados();
    },
  }
}

</script>

<style>

</style>